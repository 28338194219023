.base-avatar-style {
    width: 32px ;
    height: 32px ;
    padding: 0px 8px !important;

    text-align: center !important;
    font-feature-settings: 'liga' off, 'clig' off !important;

    /* Label/Label 3 */
    font-family: 'Source Sans Pro' !important;
    font-size: 12px;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    /* 150% */
    border-radius: 999px !important;
}

.extendeal-style {
    border: 0.5px solid var(--Primary-600, #0171e6) !important;
    background: var(--Primary-600, #0171e6) !important;

    color: var(--Neutral-White, var(--Neutral-white, #fff)) !important;
}

.connected-style {
    background: var(--Semantic-Success-100, #ddf6e2) !important;
    color: var(--Semantic-Success-600, #07ad4b) !important;
}

.error-style {
    border: 0.5px solid var(--Semantic-Error-600, #e22e2e) !important;
    background-color: transparent !important;
    color: #d20612 !important;
}

.disconnected-style {
    border: 0.5px solid var(--Neutral-700, #6b6f93) !important;
    background: var(--Neutral-white, #fff) !important;
    color: var(--Neutral-700, #6b6f93) !important;
}