/* BUTTON BASE STYLES */

.ex-button {
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-family: 'Source Sans Pro' !important;
    font-weight: 600 !important;
    padding: 10px 24px !important;
    line-height: 20px !important;
    height: 40px;
    font-size: 16px !important;
}

/*********************/
/* APPEARANCES */
/*********************/

/* PRIMARY/SOLID APPEARANCE */

.button-primary {
    background-color: #0171e6 !important;
    color: #fff !important;
}
.button-primary:hover {
    background-color: #0062c8 !important;
}
.button-primary:active {
    background-color: #0062c8 !important;
}
.button-primary:disabled {
    background-color: #e0e1e9 !important;
    color: #acaec4;
}

.button-primary:focus-visible {
    outline: 2px solid #0171e6;
    outline-offset: 2px;
}

/* PRIMARY STATUS */

.button-success {
    border: 1px solid #07ad4b !important;
    background-color: #07ad4b !important;
}
.button-success:hover {
    border: 1px solid #07ad4b !important;
    background-color: #07ad4b !important;
}
.button-warning {
    border: 1px solid #ffc700 !important;
    background-color: #ffc700 !important;
}
.button-warning:hover {
    border: 1px solid #ffc700 !important;
    background-color: #ffc700 !important;
}

/* OUTLINE APPEARANCE */

.button-outline {
    background-color: transparent !important;
    border: 1px solid #0171e6 !important;
    color: #0171e6 !important;
    padding: 10px 24px !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.button-outline:hover {
    background: #dcedff !important;
}
.button-outline:active {
    outline: 2.5px solid #0171e6 !important;
    background: none;
}
.button-outline:disabled {
    background: none !important;
    border: 1px solid #acaec4 !important;
    color: #acaec4 !important;
    outline: none !important;
}
.button-outline:focus-visible {
    outline: 2px solid #0171e6;
    outline-offset: 2px;
}

/* GHOST APPEARANCE */

.button-ghost {
    background-color: transparent !important;
    border: none !important;
    color: #0171e6 !important;
    padding: 10px 24px !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.button-ghost:hover {
    border: none !important;
    background: #dcedff !important;
}
.button-ghost:active {
    border: none !important;
    background: #badbff !important;
}
.button-ghost:disabled {
    color: #acaec4 !important;
    background: none !important;
}

/* ALTERNATIVE APPEARANCE */

.button-alternative {
    background-color: #dcedff !important;
    border: none !important;
    color: #0171e6 !important;
    padding: 10px 24px !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.button-alternative:hover {
    background-color: #0062c8 !important;
    color: white !important;
}
.button-alternative:active {
    background-color: #0056b0 !important;
    color: white !important;
}
.button-alternative:disabled {
    background-color: #e0e1e9 !important;
    color: #acaec4 !important;
}
.button-alternative:focus-visible {
    outline: 2px solid #0171e6;
    outline-offset: 2px;
}

/* LINK APPEARANCE */

.button-link {
    color: #0171e6 !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    height: 20px;
    width: 100%;
}
.button-link:hover {
    color: #0062c8 !important;
}
.button-link:active {
    color: #0056b0 !important;
}
.button-link:disabled {
    color: #acaec4 !important;
}

/* CRITICAL APPEARANCE */

.button-critical {
    background-color: transparent !important;
    border: 1px solid #e22e2e !important;
    color: #e22e2e !important;
    padding: 10px 24px !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.button-critical:hover {
    background: #ffebeb !important;
}
.button-critical:active {
    outline: 2.5px solid #e22e2e !important;
    background: none;
}
.button-critical:disabled {
    background: none !important;
    border: 1px solid #acaec4 !important;
    color: #acaec4 !important;
    outline: none !important;
}
.button-critical:focus-visible {
    outline: 3px solid #ffebeb;
}

/* LOADING */

.ex-button-loading {
    pointer-events: none;
    gap: unset !important;
}

.ex-button-loader,
.ex-button-text {
    z-index: 10;
}

.ex-button-loader {
    margin: 0 8px !important;
}

.ex-button-text {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

/* WITH ARROW */

.arrow {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease, opacity 0.5s ease;
    z-index: 10;
}

.ex-button,
.rs-btn-primary,
.button-primary:hover:enabled .arrow {
    opacity: 1;
    transform: translateX(0);
}

/* PROGRESS BAR */

.progress-bar {
    position: absolute;
    width: 100%;
    padding: 0 !important;
    z-index: 0;
}

.progress-bar > div > div {
    background: #0062c8;
    border: 1px solid #0062c8;
}

/*********************/
/* SIZES */
/*********************/

/* LG */
.button-size-lg {
    height: 48px !important;
}
/* MD */
.button-size-md {
    height: 32px !important;
    padding: 8px 12px !important;
    line-height: 18px !important;
    font-size: 14px !important;
}
/* SM */
.button-size-sm {
    height: 24px !important;
    padding: 4px 8px !important;
    line-height: 18px !important;
    font-size: 14px !important;
}
