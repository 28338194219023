
.checkbox-round div {
    line-height: 0px !important;
    padding-bottom: 0px !important;
    min-height: 0px !important;
    padding-left: 23px !important;
}

.checkbox-round div label span {
    left: 0 !important;
}
.checkbox-round:checked {
    border-radius: 9999px;
    border: 5px solid var(--Semantic-Success-600, #0171e6);
    background: var(--Neutral-White, #fff);
}

.container {
    display: grid;
    grid-template-columns: 313px 16px 80px;
    grid-template-rows: auto;
}

.row {
    display: grid;
    grid-template-columns: 313px 16px 80px;
    align-items: flex-start;
}

.column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: var(--Neutral-800, var(--800, #40435b));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-left: 8px;
}
.display-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
}

.content-text {
    color: var(--Neutral-700, #6b6f93);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
