.button-style {
  color: #0171e6 !important;
  background-color: white !important;
  border: 1px solid #0171e6 !important;
  padding: 10px 24px !important;
  font-size: 16px !important;
  font-style: normal !important; 
  font-weight: 600 !important;
  border-radius: 8px !important;
}

.button-style:hover {
  background-color: #dcedff !important;
  color: #0171e6 !important;
  border: 1px solid #0171e6 !important;
}

.button-style:focus {
  background: #dcedff !important;
  color: #0171e6;
}

.button-style:active {
  border:  2.5px solid #0171e6 !important;
}

.button-style:disabled {
  color: #acaec4 !important;
  border: 1px solid #acaec4 !important;
  background-color: white !important;
}
