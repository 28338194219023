.container {
  position: relative;
}

.wrapper-container {
  position: absolute;
  top: 0;
  left: -60px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}



.wrapper-icon{
  display: inline-flex;
  cursor: pointer;
}


.text-icon{
  display: flex;
  align-items: center;
  margin-left: 3px;
}

.trash-icon-button{
  border-radius: 0 !important;
  background-color: transparent !important;
  right: 12px !important;
}