.span-placeholder-all{
    color:#40435b
}

.checkAll-container {
    padding: 10px 2px !important;
    border-top: 1px solid #e5e5e5;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container-button {
    margin-right: 10px;
    color: var(--Neutral-White, #fff);

    /* Button/Button 2 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}
.text-error-input div[role='combobox'] {
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    border: 2px solid transparent !important;
    outline: 1px solid #E22E2E !important;
}