.delete-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.delete-alert,
.delete-alert:hover {
    color: var(--Semantic-Error-600, #e22e2e);
    font-size: 16px;
    font-style: normal;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    background-color: white;
}