.form-input-group {
    margin-bottom: 0 !important;
}

.form-input-label {
    color: #40435b;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.form-input-container {
    border-radius: 8px;
    border: 1px solid #6b6f93 !important;
    background: #fff;
}

.form-input-container:hover {
    border: 1px solid #5381c3 !important;
}

.form-input {
    padding: 10px 16px;
    height: 40px;
}

.form-input-error {
    border-radius: 8px;
    border: 1px solid #e22e2e !important;
}

.form-input-required {
    color: #e22e2e;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 4px;
}

.form-input-optional {
    color: var(--Neutral-700, #6b6f93);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 1 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
}

.form-error-message {
    color: #e22e2e;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
}

#form-input-name,
.form-input-group div div input {
    overflow: hidden;
    color: var(--Neutral-800, #40435b) !important;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Body/Body 1/Regular */
    font-family: 'Source Sans Pro';
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important; /* 125% */
}

.message-container {
    display: flex;
    justify-content: space-between;
}

.form-input:disabled {
    border: 1px solid #e0e1e9 !important;
    background: #f8f8f8 !important;
    color: #acaec4 !important;
}

.suggestions-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #fff;
    border: 1px solid #acaec4;
    border-radius: 8px;
    padding: 4px 12px;
    margin-top: 4px;
    position: absolute;
    z-index: 1000;
    width: 550px;
}

.suggestions-text-icon {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.suggestion-text {
    color: var(--Neutral-700, #40435b);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 1 */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
}
