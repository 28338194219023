.text-input {
    display: flex !important;
    height: 40px;
    padding: 10px 16px !important;
    align-items: center;
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    border: 2px solid transparent !important;
    outline: 1px solid #acaec4 !important;
}

.text-input:hover {
    border: 2px solid #0171e6 !important;
    outline: 1px solid transparent !important;
}

.text-input::placeholder {
    color: var(--Neutral-600, #acaec4);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Body/Body 1/Regular */
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.text-error-input{
    display: flex !important;
    height: 40px;
    padding: 10px 16px !important;
    align-items: center;
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    border: 2px solid transparent !important;
    outline: 1px solid #E22E2E !important;
}
.counter-characters {
    align-self: stretch;
    color: var(--Neutral-700, #6b6f93);
    text-align: right;
    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}

.form-data {
    color: #40435b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.required {
    color: #e22e2e;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 4px;
}

.placeholder {
    color: #40435b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}
