.input-container {
    margin-top: 16px;
    margin-bottom: 16px !important;
}
.required {
    color: #e22e2e;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-left: 4px;
}

.placeholder {
    color: #40435b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}