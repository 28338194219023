.progress-bar {
    border-radius: 0px 0px 0px 8px;
    height: 4px;
    transition: width 0.5s;
    position: absolute;
    bottom: 0;
    left: 0;
}

.progress-bar-info {
    background: var(--Semantic-Information-600, #1A66FC);
}
.progress-bar-error {
    background: var(--Semantic-Error-600, #E22E2E);
}
.progress-bar-success {
    background: var(--Semantic-Success-600, #07AD4B);
}
.progress-bar-warning {
    background: var(--Semantic-Warning-600, #FFC700);
}