.avatars {
    display: flex !important;
    width: 24px !important;
    height: 24px !important;
    /* padding: 0px 5px !important; */
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 999px !important;
    border: 1px solid var(--Neutral-White, #fff) !important;
    background: var(--Primary-10, #ebf5ff) !important;
    /* xs */
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06) !important;
}

.avatars-all {
    width: 32px !important;
    height: 32px !important;
    border-radius: 999px !important;
    border: 1px solid #fff !important;
    background: #ebf5ff !important;

    /* xs */
    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1) !important;

    color: #0171e6 !important;
    text-align: center !important;

    font-family: SourceSansProRegular !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
}

.text-avatar {
    color: var(--Primary-600, #0171e6) !important;
    text-align: center !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 180% */
}

.avatars-unknown-error {
    display: flex !important;
    width: 24px !important;
    height: 24px !important;
    padding: 0px 5px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 999px !important;
    border: 1px solid var(--Neutral-White, #fff) !important;
    background: #ffe0e0 !important;

    /* xs */
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06) !important;
}

.text-avatar-unknown-error {
    color: #d20612 !important;
    text-align: center !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 180% */
}

.avatars-format-validate-error {
    display: flex !important;
    width: 24px !important;
    height: 24px !important;
    padding: 0px 5px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 999px !important;
    border: 1px solid var(--Neutral-White, #fff) !important;
    background: #fff4d9 !important;

    /* xs */
    box-shadow: 0px 1px 3px 0px rgba(64, 67, 91, 0.1), 0px 1px 2px 0px rgba(64, 67, 91, 0.06) !important;
}

.text-avatar-format-validate-error {
    color: #a16205 !important;
    text-align: center !important;
    font-family: 'Source Sans Pro' !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 180% */
}

.container-button {
    height: 24px !important;
    padding: 4px 8px !important;

    gap: 8px !important;
    border-radius: 8px !important;
    border: 1px solid var(--Primary-600, #0171e6) !important;
}

.container-button span {
    color: var(--Primary-600, #0171e6) !important;

    /* Button/Button 2 */
    font-family: 'Source Sans Pro' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 128.571% */
}

.container {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}
.icon {
    display: flex !important;
    align-items: center !important;
}
.avatar-component-all {
    width: 107px !important;
    height: 24px !important;

    border-radius: 999px !important;
    border: 0.5px solid #e0e1e9 !important;
    background: #fff !important;

    box-shadow: 0px 1px 2px 0px rgba(64, 67, 91, 0.06), 0px 1px 3px 0px rgba(64, 67, 91, 0.1) !important;

    color: #40435b !important;
    text-align: center !important;

    font-family: SourceSansProRegular !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
}

.container-n-avatars {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.text-avatar-n-droguerias {
    color: #40435b !important;
    text-align: center !important;

    font-family: SourceSansProRegular !important;

    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important; /* 150% */
}

.line-divider {
    margin: 8px 0 !important;
}

.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-container .tooltip-text {
    visibility: hidden;
    width: 120px; /* Ajusta el ancho según sea necesario */
    background-color: #0B2033;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Posición del tooltip */
    position: absolute;
    z-index: 1;
    top: 125%; /* Posiciónalo abajo del elemento */
    left: 50%;
    transform: translateX(-50%);
    
    /* Flecha del tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container .tooltip-text::after {
    content: '';
    position: absolute;
    bottom: 100%; /* Arriba del tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #0B2033 transparent;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }