.container {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--Semantic-Success-100, #c6d9fe);
    background: var(--Semantic-Success-50, #f2f7ff);
    position: relative;
    padding: 16px;
    display: flex;
    margin-bottom: 16px;
    pointer-events: all;
}
.container-info {
    background: var(--Semantic-Information-50, #f2f7ff);
    border: 1px solid var(--Semantic-Information-100, #c6d9fe);
}
.container-error {
    background: var(--Semantic-Error-50, #ffebeb);
    border: 1px solid var(--Semantic-Error-100, #f8cbcb);
}
.container-success {
    background: var(--Semantic-Success-50, #ebf9f3);
    border: 1px solid var(--Semantic-Success-100, #ddf6e2);
}
.container-warning {
    background: var(--Semantic-Warning-50, #fff9e6);
    border: 1px solid var(--Semantic-Warning-100, #ffea9f);
}
