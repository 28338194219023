.container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}
.header-title {
    color: rgba(64, 67, 91, 0.9);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.container-close-btn{
    background-color: transparent !important;
}
.container-close-btn:hover, .container-close-btn:active, .container-close-btn:focus {
    background-color: transparent !important;

}
.icon{
    color: #40435b;
}