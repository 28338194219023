/* Mantener el !important para los estilos */
.modal-title {
    color: #40435b !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    overflow-wrap: break-word !important;
    white-space: normal !important;
}
.modal-content {
    color: #6b6f93 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: 0px !important;
    padding: 0px !important;
}
.modal-footer {
    margin-top: 32px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
