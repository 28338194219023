.base-container {
    width: 32px;
    height: 32px;
    border-radius: 8px !important;
}

/* DISABLED */
.icon-container {

    background: var(--Neutral-100, #E0E1E9) !important;
}

.icon-container:hover, .icon-container:active, .icon-container:focus {
    border-radius: 8px !important;
    background: var(--Neutral-100, #E0E1E9) !important;
}

/* DISABLED */
/* ON */
.icon-container-ON {

    background: var(--Semantic-Success-600, #07AD4B) !important;
    box-shadow: 0px 1px 4px 0px rgba(21, 177, 86, 0.22) !important;
}

.icon-container-ON:hover,.icon-container-ON:focus,.icon-container-ON:active {

    background: var(--Semantic-Success-600, #07AD4B) !important;
    box-shadow: 0px 1px 4px 0px rgba(21, 177, 86, 0.22)  !important;
}

/* ON */

/* OFF */
.icon-container-OFF {

    border: 1px solid var(--Neutral-700, #6B6F93) !important;
    background: var(--Neutral-white, #FFF) !important;
}

.icon-container-OFF:hover, .icon-container-OFF:focus, .icon-container-OFF:active {

    border: 1px solid var(--Neutral-700, #6B6F93) !important;
    background: var(--Neutral-white, #FFF) !important;
}

/* OFF */