.button-style {
  background-color: #0171e6 !important;
  border: 1px solid #0171e6 !important;
  color:#FFF !important;
  padding: 10px 24px !important;
  border-radius: 8px !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
}

.button-style:hover {
  background-color: #0062C8 !important;
  color: #FFF !important;
}

.button-style:focus-visible {
  background: #0062C8 !important;
  color: #FFF !important;
}

.button-style:active {
  background-color: #0056B0 !important;
  color: #FFF !important;
}

.button-style:disabled {
  color: #ACAEC4 !important;
  background-color: #E0E1E9 !important;
  padding: 10px 24px !important;
  font-size: 16px !important; 
  font-weight: 600 !important;
  border: 1px solid #E0E1E9 !important;
}


.button-style-cancel{
  background-color: #E22E2E !important;
  border: 1px solid #E22E2E !important;
  color:#FFF !important;
  padding: 10px 24px !important;
  border-radius: 8px !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
}

.button-style-detail{
  background-color: #0171e6 !important;
  border: 1px solid #0171e6 !important;
  color:#FFF !important;
  padding: 8px 12px !important;
  border-radius: 8px !important; 
  font-size: 14px !important;
  font-weight: 600 !important;
}