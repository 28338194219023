.button-style {
  color: #0171e6 !important;
  background-color: white !important;
  border: none !important;
  padding: 10px 24px !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}



.button-style:hover {
  background-color: #dcedff !important;
  color: #0171e6 !important;
  border: none !important;
}

.button-style:focus {
  background: #dcedff !important;
  color: #0171e6 !important;
}

.button-style:active {
  background-color: #BADBFF !important;
}

.button-style:disabled {
  color: #acaec4 !important;
  border: none !important;
  background-color: white !important;

}



