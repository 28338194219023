@font-face {
    font-family: SourceSansProRegular;
    src: url('/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
    font-family: SourceSansProBold;
    src: url('/fonts/SourceSansPro-Bold.ttf');
}

@font-face {
    font-family: SourceSansProSemiBold;
    src: url('/fonts/SourceSansPro-Semibold.ttf');
}

@font-face {
    font-family: SourceSansProLight;
    src: url('/fonts/SourceSansPro-Light.ttf');
}

/* body {
  background-color: #eaeaea !important;
} */

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    border-radius: 15px !important;
}

.rs-picker-daterange-predefined .rs-btn {
    margin: 10px;
    border-radius: 15px;
    border: 0.5px solid #0b2033;
    color: #0b2033;
    font-family: SourceSansProRegular;
}

.rs-picker-daterange-predefined .rs-btn:hover {
    color: #0b2033;
    text-decoration: none;
}

.rs-picker-daterange-predefined .rs-btn:active,
.rs-picker-daterange-predefined .rs-btn:focus {
    border: 2px solid #0b2033 !important;
    color: #0b2033 !important;
    text-decoration: none !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #0171e6 !important;
}

.rs-calendar-table-cell-in-range:before {
    background-color: #dcedff !important;
    display: block !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    border-radius: 2px;
}

.rs-calendar-table-cell-content:hover {
    background-color: #dcedff !important;
    color: #222 !important;
    border: 1px solid #0171e6 !important;
}

.rs-calendar-table-cell-content:active,
.rs-calendar-table-cell-content:focus,
.rs-calendar-table-cell-content:hover {
    border: 1px solid rgba(0, 0, 0, 0.8) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
}

.rs-calendar-table-header-cell-content {
    font-family: SourceSansProBold !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
   /*  color: #40435b !important; */
    padding-left: 16px;
    font-family: SourceSansProRegular;
}
/* 
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #222 !important;
} */
/* 
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #222;
} */

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    box-shadow: none !important;
}

.rs-btn-primary {
    background-color: #0171e6;
    font-family: Source Sans Pro !important;
    font-size: 14px;
}

.rs-picker-daterange-header {
    display: none;
}

/* .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  padding: 0 25px !important;
} */

.rs-calendar-month-dropdown-year-active {
    color: #0171e6 !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content {
    border-radius: 15px !important;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
    color: #0171e6 !important;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: #0171e6 !important;
    color: white !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: rgba(1, 113, 230, 0.15) !important;
    color: #0171e6 !important;
}

.rs-calendar-table-cell-day {
    font-family: SourceSansProRegular;
}

/* .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  background-color: rgba(29, 202, 211, 0.2) !important;
  color: #0171E6 !important;
} */

/* .rs-picker-select-menu-item:hover{
  background-color: rgba(29, 202, 211, 0.2) !important;
  color: #0171E6 !important;
} */

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    outline: 1px solid #0171e6;
}

/* .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #0171E6 !important;
} */
/* .rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #222 !important;
} */

.rs-input-group:focus-within {
    outline: 2px solid #0171e6 !important;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    outline: 1px solid #0171e6;
}

.rs-picker-tag-wrapper {
    display: flex !important;
    align-items: baseline !important;
}

.rs-picker-tag .rs-tag {
    margin-left: 30px !important;
}

.rs-tag {
    background-color: rgba(29, 202, 211, 0.2) !important;
    border-radius: 20px !important;
}

.rs-tag-text {
    font-family: SourceSansProRegular;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
    margin-left: 0px !important;
}

.rs-input-group-lg.rs-input-group > .rs-input {
    font-size: 14px !important;
}

.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
    background-color: #dcedff !important;
    color: #0171e6 !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: #0171e6 !important;
}

.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
    background-color: #0171e6 !important;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled) {
    border-color: #0171e6 !important;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
    outline: 1px solid #0171e6 !important;
}

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
    overflow: hidden;
}

/* .rs-picker:not(.rs-picker-disabled):hover{
  border-color: #0171E6 !important;
} */
/* .rs-picker:not(.rs-picker-disabled):hover {
  border-color: #222 !important;
} */

.rs-picker-default .rs-picker-toggle {
    transition: none;
    height: 40px;
}

.rs-tooltip[class*='placement-bottom']:after {
    border-bottom-color: #0b2033 !important;
}

/* .rs-input-group.rs-input-group-inside .rs-input-group-addon{
  padding: 0px !important;
} */
/* .rs-panel-body{
  padding: 0px !important;
} */

/* FOCUS INPUT  */
.rs-input-group:focus-within {
    outline: none !important;
}

.rs-pagination-btn.rs-pagination-btn-active {
    color: #0f7ff4 !important;
    background-color: #e2ecfe !important;
    border: none !important;
    box-shadow: 0 !important;
}

.rs-pagination-btn.rs-pagination-btn-active:focus,
.rs-pagination-btn.rs-pagination-btn-active:hover {
    color: #0f7ff4 !important;
    background-color: #e2ecfe !important;
    border: none !important;
    box-shadow: 0 !important;
    border-radius: 4px !important;
}

.rs-pagination-symbol svg {
    width: 24px !important;
    height: 24px !important;
}

.rs-pagination-btn[title='Siguiente'] {
    width: 28px !important;
    height: 28px !important;
    color: #0f7ff4 !important;
    padding: 0 8px !important;
}

.rs-pagination-btn[title='Anterior'] {
    width: 28px !important;
    height: 28px !important;
    color: #0f7ff4 !important;
    padding: 0 8px !important;
}

.rs-pagination-btn {
    width: 28px !important;
    height: 28px !important;
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding: 0 12px;
    color: #6b6f93;
}

.rs-pagination-btn.rs-pagination-btn-disabled {
    color: #acaec4 !important;
}

.rs-pagination-btn.rs-pagination-btn-active:focus,
.rs-pagination-btn.rs-pagination-btn-active:hover {
    box-shadow: 0 !important;
}

.rs-pagination-group-limit {
    font-family: SourceSansProRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-left: 16px;
}

/* .rs-uploader-file-items {
  display: none !important;
} */

/* .rs-panel-bordered {
  border: none !important;
} */

.rs-picker-select-menu {
    z-index: 2500 !important;
}

/* .rs-picker-menu.rs-picker-select-menu {
  padding: 16px 0px !important;
  
} */
.react-joyride__overlay {
    mix-blend-mode: normal !important;
}

.react-joyride__spotlight {
    background-color: transparent !important;
}

#drawer-cart-modal .rs-modal-content {
    padding: 0 !important;
    position: fixed;
    right: 30px;
    top: 18px;
    transform: translateX(0);
}
.rs-popover {
    padding: 16px !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(64, 67, 91, 0.9) !important;
    opacity: 0.8 !important;
}
.rs-picker-value-item {
    padding-left: 15px;
}

.rs-picker-menu {
    z-index: 2500 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.Launcher__IconLauncherWrapper-sc-1a4mssd-0 .hZuEVr {
    position: relative !important;
    left: -10px !important;
}
.IconLauncher__BaseLauncher-sc-13k3mjz-0 .IconLauncher__ShapedLauncher-sc-13k3mjz-1 .ddSvff .reagan--widget-loaded {
    position: relative !important;
    left: -10px !important;
}
/* .rs-panel-collapsible > .rs-panel-header > .rs-icon {
  display: none !important;
} */
.rs-anim-collapse .rs-anim-in .rs-panel-collapse {
    background-color: #fff !important;
}

.rs-panel-collapsible > .rs-panel-header {
    padding: 16px 24px !important;
}

/* Clase custom panel-container para no afectar otras */
.panel-container .rs-panel-header {
    padding: 16px !important;
}

.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-default.rs-picker-disabled .rs-picker-toggle {
    background-color: white !important;
}
.rs-modal-content {
    padding: 24px;
}
.rs-modal-wrapper[tabindex='-1'] {
    display: flex;
    align-items: center;
}

.rs-drawer-wrapper[tabindex='-1'] {
    z-index: 1250;
}
.rs-message:not(.rs-message-hiding) + .rs-message {
    margin-top: 0px !important;
}
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-default.rs-picker-disabled .rs-picker-toggle {
    border-radius: 8px !important;
    color: var(--Neutral-600, #ACAEC4) !important;

    border: 1px solid var(--Neutral-300, #e0e1e9) !important;
    background: var(--Neutral-50, #f8f8f8) !important;
}

.rs-picker-default .rs-picker-toggle {
    width: 100%;
}

.rs-picker-disabled {
    opacity: 1 !important;
}
/* .rs-picker-toggle-read-only {
    color: #acaec4 !important;
} */
.rs-picker-daterange-predefined {
    height: 331px !important;
}

.rs-picker-toggle-value {
    text-overflow: clip !important;
}
.rs-picker-daterange-predefined .rs-btn:hover {
    background: #ebf5ff !important;
    border: 1px solid #ebf5ff !important;
    color: #0171e6 !important;
}

.rs-picker-daterange-predefined .rs-btn:focus-within {
    border: 2px solid #0171e6 !important;
}
.rs-panel-collapsible > .rs-panel-header > .rs-icon {
    visibility: hidden;
}

/* this classname with id only modify styles on Pharmacy Selector */
#wrapper-pos-reference.rs-picker-toggle {
    padding-top: 0px !important;
}

#react-joyride-step-3 + #react-joyride-portal .react-joyride__overlay {
    background: linear-gradient(0deg, rgb(81, 84, 107) 0%, rgb(81, 84, 107) -5%, rgba(81, 84, 107, 0) 47%) !important;
    z-index: 2200 !important;
}

#react-joyride-step-0 + #react-joyride-portal .react-joyride__overlay {
    z-index: 2500 !important;
}

#react-joyride-step-0 .__floater.__floater__open {
    z-index: 2550 !important;
}

#react-joyride-step-3 .__floater.__floater__open {
    z-index: 2550 !important;
}

#react-joyride-step-4 .__floater.__floater__open {
    z-index: 2550 !important;
}
#react-joyride-step-5 .__floater.__floater__open {
    z-index: 2550 !important;
}
#react-joyride-step-6 .__floater.__floater__open {
    z-index: 2550 !important;
}
#react-joyride-step-7 .__floater.__floater__open {
    z-index: 2550 !important;
}

#react-joyride-step-7 + #react-joyride-portal .react-joyride__overlay {
    display: none !important;
}
.rs-btn-block + .rs-btn-block {
    margin-top: 0 !important;
}

/* FOOTER TABLE */
.cell-in-footer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiBackdrop-root {
    background-color: rgba(64, 67, 91, 0.7) !important;
}

/* Custom Class panel-container para no afectar otras */
.panel-container .rs-panel-header + .rs-panel-collapse .rs-panel-body {
    padding: 0px 16px 16px !important;
}

.rs-btn.rs-btn-link.rs-btn-sm.selected-filter {
    border: 2px solid #0171e6;
}
.modal-alert-handler .rs-modal-dialog .rs-modal-content {
    padding: 24px !important;
}
.rs-modal-backdrop {
    z-index: 1200;
}

.rs-modal-wrapper {
    z-index: 1300;
}

/* MODAL CREATE ALERT ADMIN */
.modal-alert-handler.rs-modal-xs {
    width: 370px !important;
}

.custom-drawer-content .rs-drawer-content {
    border-radius: 16px 0px 0px 16px;
    width: 473px;
    right: 0;
}

.custom-drawer-content.rs-drawer-left.rs-drawer-sm,
.custom-drawer-content.rs-drawer-right.rs-drawer-sm {
    width: 473px;
}

.custom-drawer-content .rs-drawer-body-close {
    display: none;
}

.custom-drawer-content .rs-drawer-body {
    padding: 32px;
}

.drawerAlert-picker .rs-picker-toggle.rs-btn.rs-btn-default {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    border: 2px solid transparent !important;
    border-radius: 8px !important;
    box-sizing: border-box !important;
    outline: 1px solid #acaec4 !important;
}

.drawerAlert-picker .rs-picker-toggle-placeholder {
    overflow: hidden;
    color: #acaec4;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.drawerAlert-picker .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    right: 16px !important;
}

.drawerAlert-picker .rs-picker-toggle-caret {
    color: #6b6f93 !important;
    width: 16px;
    height: 16px;
}

.drawerAlert-picker .rs-picker-toggle.rs-btn.rs-btn-default:hover {
    border: 2px solid #0171e6 !important;
    outline: 1px solid transparent !important;
}

.checkTreePicker_Admin .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.checkTreePicker_Admin .rs-picker-value-item {
    padding-left: 0px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.checkTreePicker_Admin .rs-picker-toggle {
    padding: 10px 16px;
    height: 40px;
    border: 2px solid transparent !important;
    border-radius: 8px !important;
    box-sizing: border-box !important;
    outline: 1px solid #acaec4 !important;
}
.checkTreePicker_Admin .rs-picker-toggle:hover {
    border: 2px solid #0171e6 !important;
    outline: 1px solid transparent !important;
}

.checkTreePicker_Admin .rs-btn-close .rs-icon {
    color: #6b6f93 !important;
    width: 16px;
    height: 16px;
}

.checkTreePicker_Admin .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 10;
}

.checkTreePicker_Admin .rs-picker-value-count {
    display: flex;
    padding: 4px 8px;
    color: #40435b;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    border: 1px solid #c6d9fe;
    background: #f2f7ff;
    position: absolute;
    right: 30px;
    left: auto;
    top: 3px;
}

.checkTreePicker_Admin .rs-picker-value-list {
    width: 85%;
    top: -2px;
    position: relative;
}

.checkbox-admin .rs-checkbox-wrapper {
    top: 16px;
}

.checkbox-admin .rs-checkbox-checker {
    padding-bottom: 0px;
    padding-top: 16px;
}

.rs-drawer-backdrop{
    z-index: 5000;
}

.rs-drawer-wrapper{
    z-index: 5500 !important;
}

/* .rs-panel-header {
    padding:24px !important;
} */