.stepper-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
}

.step-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

/* Paso activo */
/* Estilado para el icon que tiene texto y es numérico*/
.step-item.active .step-icon {
    background-color: #007bff;
    border: none;
}
/* Estilado del texto del icon cuando es numérico */
.step-item.active .step-icon span {
    color: var(--Neutral-White, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Button/Button 3 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
}
/* Estilado del texto del step */
.step-item.active .step-title-text {
    color: var(--Neutral-800, #40435b);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/SemiBold */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
}

/* Paso aun sin completar */
/* Estilado para el icon que tiene texto y es numérico*/
.step-item .step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--Neutral-100, #e0e1e9);
    background: var(--Neutral-50, #f5f6fa);
}

/* Estilado del texto del icon cuando es numérico */
.step-item .step-icon span {
    color: var(--Neutral-700, #6b6f93);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Caption/Caption 2 */
    font-family: 'Source Sans Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}
/* Estilado del texto del step */
.step-item .step-title-text {
    color: var(--Neutral-700, #6b6f93);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}
/* Paso aun sin completar */

/* Paso completado */
/* Estilado para el icon que deja de ser un número y es un checkIcon y el texto del step */
.step-item.completed .step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid var(--primary, #0171e6);
}
/* Estilado del texto del step */
.step-item.completed .step-title-text {
    color: var(--Primary-600, var(--primary, #0171e6));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/Body 2/Regular */
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}
/* Paso completado */
