.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
}

.icon svg {
    width: 20px;
    height: 20px
}

.icon-info {
    color: var(--Semantic-Information-600, #1A66FC);
}
.icon-error {
    color: var(--Semantic-Error-600, #E22E2E);
}
.icon-success {
    color: var(--Semantic-Success-600, #07AD4B);
}
.icon-warning {
    color: var(--Semantic-Warning-600, #FFC700);
}