.container-table-head{
    /* container */
    background-color:#D2F4F6;
}

.container-loading-table-head{
    background-color: #EDEEF4;
}
.table-cell-container{
    padding: 8px !important;

   
}

.table-cell-container span {
    font-family: Source Sans Pro;
    font-weight: 600;
    line-height: 18px;
}