.content {
    display: flex;
    gap: 12px;
    flex: 1;
}
.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    min-width: 0;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.action-container {
    display: flex;
    align-items: center;
    width: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #0171E6;
    text-decoration: underline;
    background: transparent;
    cursor: pointer;
    padding: 0;
}

.close-container {
    display: flex;
    cursor: pointer;
}

.close-container svg {
    height: 16px;
    width: 16px;
}