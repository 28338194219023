.container {
    max-width: 420px;
    max-height: 90px;
    border-radius: 8px;
    border: 1px solid var(--Semantic-Success-100, #DDF6E2);
    background: var(--Semantic-Success-50, #EBF9F3);
    box-shadow: 0px 10px 15px -3px rgba(64, 67, 91, 0.10), 0px 4px 6px -2px rgba(64, 67, 91, 0.05);
    z-index: 999999;
    position: relative;
    padding: 16px;
    display: flex;
    gap: 16px;
    pointer-events: all;
    min-height: 70px;
}
.single-line {
    height: 56px;
}
.container-info {
    background: var(--Semantic-Information-50, #F2F7FF);
    border: 1px solid var(--Semantic-Information-100, #C6D9FE);
}
.container-error {
    background: var(--Semantic-Error-50, #FFEBEB);
    border: 1px solid var(--Semantic-Error-100, #F8CBCB);
}
.container-success {
    background: var(--Semantic-Success-50, #EBF9F3);
    border: 1px solid var(--Semantic-Success-100, #DDF6E2);
}
.container-warning {
    background: var(--Semantic-Warning-50, #FFF9E6);
    border: 1px solid var(--Semantic-Warning-100, #FFEA9F);
}